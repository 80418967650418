/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS Colors
:::::::::::::: -- * A_SETTINGS bady
:::::::::::::: -- * A_SETTINGS General
:::::::::::::: -- * A_SETTINGS Footer
:::::::::::::: -- * A_SETTINGS Button boostrap
:::::::::::::: -- * A_SETTINGS elenco-elementi
:::::::::::::: -- * A_SETTINGS slick-slider
:::::::::::::: -- * A_SETTINGS time-scrollable-contents
:::::::::::::: -- * A_SETTINGS time__content_container
:::::::::::::: -- * A_SETTINGS time_date--navigation
:::::::::::::: -- * A_SETTINGS time-hour-list
:::::::::::::: -- * A_SETTINGS badge-small-top
:::::::::::::: -- * A_SETTINGS time_header
:::::::::::::: -- * A_SETTINGS time__slidingHeader
:::::::::::::: -- * A_SETTINGS daySlideSelection
:::::::::::::: -- * A_SETTINGS headerGrid
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS Size
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
$size-sm: 576px;
$size-md: 768px;
$size-lg: 992px;
$size-lg-plus: 1023px;
$size-lx: 1200px;

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS Colors
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

$primary: #a91354;
$secondary: #27287a;
$gradient: linear-gradient(
  90deg,
  rgba(39, 40, 122, 1) 0%,
  rgba(167, 24, 86, 1) 100%
);
$gradient-hover: linear-gradient(
  90deg,
  rgba(167, 24, 86, 1) 0%,
  rgba(39, 40, 122, 1) 100%
);

$white: #ffffff;
$black: #000000;

$gray-p: #505050;
$gray-1: #ffffff3a;
$gray-light: rgba(0, 0, 0, 0.1);

$trasparent: rgba(255, 255, 255, 0);
$shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

$gray-light-f5: #f5f5f5;

.color-white {
  color: $white;
}

.color-black {
  color: $black;
}

.color-primary {
  color: $primary;
}

.color-secondary {
  color: $secondary;
}

.bg-primary {
  background: $primary;
}

.bg-secondary {
  background: $secondary !important;
}

.bg-gradient {
  background: $secondary;
  background: $gradient;
}

.bg-gray-light {
  background: $gray-light;
}

.bg-gray-light-f5 {
  background: $gray-light-f5;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS bady
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
body {
  color: $gray-p;
  width: 100%;
  overflow-x: hidden;
  line-height: 1.5;
  font-size: 1rem;

  #header {
    padding: 20px !important;

    .header-row {
      justify-content: space-between;
    }
  }

  .header-row {
    margin: 0px auto;
    flex-wrap: nowrap;
    height: inherit;
  }

  .header-row-21 {
    margin: 0px auto;
    flex-wrap: nowrap;
    height: inherit;
    padding-bottom: 15px;
  }
  .header-row-22 {
    margin: 0px auto;
    flex-wrap: nowrap;
    height: inherit;
    padding-bottom: 15px;
  }

  .header-row-3 {
    margin: 0px auto;
    flex-wrap: nowrap;
    height: inherit;
    padding-bottom: 10px;
  }

  .header-row-4 {
    margin: 0px auto;
    flex-wrap: nowrap;
    height: inherit;
    padding-bottom: 15px;
  }

  .logo-header {
    img {
      max-height: 25px;
      width: auto;
      min-width: 65px;
      transform: translate3d(0, 0, 0);
      @media (min-width: 576px) {
      }

      @media (min-width: 768px) {
        max-height: 38px;
      }

      @media (min-width: 992px) {
      }

      @media (min-width: 1200px) {
      }
    }
  }

  #dashboard #logo,
  #logo,
  .main-logo {
    position: relative;
    margin: 0 !important;
    margin-top: 0px !important;
    width: calc(100% - 70px);
    text-align: left;
    cursor: pointer;
  }
}

.icon-header {
  min-width: 20px;
  max-width: 25px;
}



.time__slidingHeader .time_date--navigation:after {
  display: block;
  content: "";
  background: #27287a;
  width: 88px;
  height: 80px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -44px;
  border: 1px solid transparent;
  border-radius: 5px;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS General
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

b,
strong {
  font-weight: 600;
}

a {
  color: $primary;
}

.listing-desc-headline {
  border-top: 1px solid $gray-light;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS Footer
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

#foot {
  .MuiCheckbox-root {
    color: #fff;
    font-size: 10px;
  }

  h5 {
    color: $white;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS Button boostrap
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.btn {
  text-transform: uppercase;
  border-radius: 15px;
  font-weight: 700;
  border: 0;

  &.btn-booking {
    text-transform: capitalize;
    padding: 20px 10px;
    width: 100%;
  }
}

@mixin btn-color($bg, $border, $color, $bg-hover, $border-hover, $color-hover) {
  color: $color;
  background: $bg;

  border: solid 1px $border;
  &:hover {
    color: $color-hover;
    background: $bg-hover;
    border-color: $border-hover;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background: $bg-hover;
    border-color: $border-hover;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:disabled,
  .show > &.dropdown-toggle {
    background: $bg;
    border-color: $bg;
    box-shadow: 0 0 0 0.05rem $border;
    color: $color;
  }
  &.disabled,
  &:disabled {
    opacity: 0.5;
  }
  &.focus,
  &:focus {
    box-shadow: 0 0 0 0.05rem $border;
  }
}

.btn-primary {
  @include btn-color(
    $primary,
    $primary,
    $white,
    $secondary,
    $secondary,
    $white
  );
}

.btn-outline-primary {
  @include btn-color($trasparent, $primary, $gray-p, $primary, $primary, $white);
}

.btn-secondary {
  @include btn-color(
    $secondary,
    $secondary,
    $white,
    $primary,
    $primary,
    $white
  );
}

.btn-outline-secondary {
  @include btn-color(
    $trasparent,
    $secondary,
                  $gray-p,
    $secondary,
    $secondary,
    $white
  );
}

.btn-gradient {
  @include btn-color(
    $gradient,
    $primary,
    $white,
    $gradient-hover,
    $primary,
    $white
  );
}

.btn-sm-block {
  display: block;
  width: 100%;
}

@media (min-width: 576px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .btn-sm-block {
    display: block;
    width: auto;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}




/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS elenco-elementi
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.elenco-elementi .card .priceDisplayer {
  text-align: center;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS time_hotel--panel
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.time_hotel--panel {
  background: $primary;
  color: $white;
  font-size: 0.9em;
  line-height: 1.3;
  padding: 4px 0;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;

  &:hover {
    background: $secondary;
  }

  a,
  strong {
    color: $white;
  }
}

.time_hotel--panel_delete {
  background: $gray-light;
  color: $black;
  font-size: 0.9em;
  line-height: 1.3;
  padding: 4px 0;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;

  a,
  strong {
    color: $primary;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS slick-slider
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.slick-slider {
  .slick-prev:before,
  .slick-next:before {
    color: $gray-p;
    background: white;
    font-size: 18px;
  }

  .slick-prev:hover:before,
  .slick-next:hover:before {
    color: $primary;
    background: $white;
  }
}

.slider-service {
  .slick-next {
    right: 0px;
    transform: translate3d(40px, -50%, 0);
  }
  .slick-prev {
    left: 0px;
    transform: translate3d(-40px, -50%, 0);
  }
}

.sliderBackg {
  height: 450px;
  max-height: 450px;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS MuiButtonBase
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.slick-arrow {
  background: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.slick-prev {
  left: 3px;
  transform: translate3d(20px, -50%, 0);

  &:hover {
    background: $white;
  }
}

.slick-next {
  right: 3px;
  transform: translate3d(-20px, -50%, 0);

  &:hover {
    background: $white;
  }
}

.MuiButton-containedPrimary {
  background: $primary !important;
  border-color: $primary !important;
}

.MuiButtonGroup-grouped:not(:last-of-type) {
  border-color: $primary !important;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS time-scrollable-contents
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.time-scrollable-contents {
  /*  overflow-y: auto;*/
  /*  max-height: 80vh;*/
  padding-bottom: 45px;
  padding-top: 25px;
  .description {
    font-size: 12px!important;
  }
}

.time-scrollable-contents {
  .slick-arrow {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .time-scrollable-contents {
    .slick-arrow {
      display: none !important;
    }
  }
}

@media (min-width: 768px) {
  .time-scrollable-contents {
    .slick-arrow {
      display: block !important;
    }
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS header
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

#header {
  .text-small {
    color: $white;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.3rem;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS MuiPaper-root
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.MuiPaper-root {
  background: $white !important;
  width: auto;

  .MuiTypography-root {
    color: $gray-p;
  }

  .color-primary .MuiTypography-root {
    color: $primary;
  }

  .MuiList-root {
    padding-top: 0;
  }
}

.MuiTabs-flexContainer {
  .MuiTab-textColorPrimary {
    background: $gray-light;
    border-right: 1px white solid;

    &.Mui-selected {
      background: $primary;
    }
  }
}

.MuiTabs-indicator {
  display: none !important;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS time__content_container
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.time__content_container {
  h4 {
    font-size: 1.3em;
  }

  .card {
    margin: 10px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    border-radius: 20px;
  }

  .card-img-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .card-title a {
    color: #333;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS time_date--navigation
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.time_date--navigation {
  padding-bottom: 0px;
  font-size: 1.3em;

  button:not(.time_header-back) {
    border: 0;
    background: #fff;
  }

  strong {
    line-height: 1.2em;
  }

  a {
    color: #fff;
    display: inline-flex;

    &:hover {
      color: #fff;
    }
  }

  strong span {
    font-size: 0.85em;
    color: #aaa;
  }

  .fa-w-14 {
    width: 1.2em;
    height: 1.2em;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS time-hour-list
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.teetime-hour-list {
  button {

    color: $black !important;
    background: $gray-light;



    border: 0;
    padding: 3px 7px;
    width: 28px;
    height: 28px;

    i {
      color: $black !important;
    }


  }

  .arrow-button {
    color: $white !important;
    background: $primary;
    border: 1px;
    border: 0;

    width: 28px;
    height: 28px;
    font-size: 10px;

    i {
      margin-left: -3px;
      margin-top: -10px;
      font-size: 20px;
      position: absolute;
      text-align: center;
    }
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS time-hour-list
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.time-hour-list {
  padding: 0 20px;
  height: 55px;
  border-top: 1px solid #eaeaea;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;

  &:first-child {
    border-top: 0;
  }

  &:hover {
    z-index: auto;
  }

  button {
    color: $white;
    background: $primary;

    border: 0;
    padding: 0 10px;
    width: 35px;
    height: 35px;
    line-height: 20px;
    font-size: 30px;
    border-radius: 50%;
    &:hover {
      background: $primary;
    }
  }

  .dt a {
    color: #fff;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS badge-small-top
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.badge-small-top {
  position: absolute;
  top: -10px;
  right: -20px;
  font-size: 12px !important;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  line-height: 20px !important;
  background: $secondary;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS time_header
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.time_header-back {


  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0);
  color: #fff;



  width: 30px !important;
  height: 30px !important;
  line-height: 23px;
  font-size: 24px;
display: inline-block;
  padding:0;
  margin:0;
  text-align: center;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {

    width: 40px;
    height: 40px;
    line-height: 36px;
    font-size: 30px;
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }

}

.header-row-21 .time_header-back  {
  top: 0px!important;
}

.header-row-3 .time_header-back  {
  top: 0!important;
}

.space-between {
  justify-content: space-between;
}




.btn-best-time a {
  padding: 0;

  margin: 0;
  font-weight: 900;
  padding-left: 5px;
}





.time_header {
  color: $white;

  padding: 18px 15px 5px !important;
  background: $secondary;
  background: $gradient;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 16;
  display: flex;
  align-items: center;

  h2 {
    color: $white;
    font-size: 1rem;
    line-height: 36px;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  .text-small {
    color: $white;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.3rem;
  }

  + .container {
    padding-top: 80px !important;
  }

  .time_header-dates {
    font-size: 1rem;
    line-height: 36px;
    font-weight: 700;
  }

  .icon {
    height: 22px;
    width: auto;
  }

  .ibg-back {
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0.2);
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    padding-right: 2px;
    line-height: 36px;
    font-size: 30px;
    cursor: pointer;
  }

  .item-menu-calendar {
    padding-top: 10px;

    @media (max-width: $size-md) {
      .text-small {
        display: none;
      }
    }
  }
}

.MuiCalendarPicker-root{
  .MuiPickersDay-root.Mui-disabled {
    color: lightgray;
    background: white;
    border: solid 0px lightgray;
  }
  .MuiPickersDay-root:hover {
    border: solid 1px lightgray;
    background: rgba(0, 0, 0, 0.1);
  }
  .MuiPickersDay-root {
    color: #00c13f;
    font-weight: 800;
    background: rgba(0, 0, 0, 0.04);
  }

}


/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS time__slidingHeader
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.time__slidingHeader {
  padding: 10px 0px;
  color: $white;
  background: $gradient;
  height: 118px;
  margin-bottom: 20px;
  position: relative;

  .text-small {
    color: $white;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.3rem;
  }

  &:after {
    display: block;
    content: "";
    background: rgb(39, 40, 122);
    width: 88px;
    height: 80px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -44px;
    border: 1px solid transparent;
    border-radius: 5px;
  }

  .daySlideSelection {
    color: #fff;
    width: 100px;
  }

  .ibg-back {
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0.2);
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    padding-right: 2px;
    line-height: 36px;
    font-size: 30px;
    cursor: pointer;
  }

  .item-menu-calendar {
    padding-top: 10px;
  }
}

.css-187mznn-MuiSlider-root {
  color: $primary !important;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS slick-dots
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.slick-dots li {
  background-color: $white !important;
}

.card {
  .imageGolf {
    .slick-dots {
      bottom: 30px !important;
    }

    @media (max-width: $size-lg-plus) {
      .slick-dots {
        bottom: 7px !important;
      }
    }
  }
}

.logo-struttura {
  max-width: 110px;
}

.StructureMarkerDisplayer {
  .slick-dots {
    bottom: 7px !important;
  }

  .slick-dots li {
    background-color: $white !important;
  }

  .view-in-map {
    font-size: 12px;
    color: $primary;
    cursor: pointer;
  }

  &.Displayer_pacchetto {
    .descrizione span {
      display: none;
    }
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS daySlideSelection
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.daySlideSelection {
  a {
    color: $white;
  }

  .day {
    text-transform: uppercase;
    font-size: 0.65em;
    line-height: 1.3rem;
  }

  .number {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.1rem;
  }

  .month {
    font-size: 0.55rem;
    line-height: 1rem;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS headerGrid
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.headerGrid {
  color: $white;

  .ibg-back {
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0.2);
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    padding-right: 2px;
    line-height: 36px;
    font-size: 30px;
    cursor: pointer;
  }

  .text-small {
    color: $white;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.3rem;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS home-selection
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.home-selection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  padding-top: 10rem;
  padding-bottom: 10rem;
  min-height: 512px;

  h3 {
    font-weight: 300;
    color: white;
  }

  .home-selection-button-container {
    text-align: center;
    display: flex;
    /* margin: auto; */
    width: 100%;
    justify-content: center;
    column-gap: 1rem;
    align-items: center;

    .searchButtons {
      width: auto !important;
      min-width: 80px;
      padding: 0 13px !important;
      background: $gray-1 !important;
      margin: 0 !important;

      &:hover {
        background: $secondary !important;
      }
    }

    .searchButtons-active {
      padding: 0 13px !important;
      margin: 0 !important;
      width: auto !important;
      background: $primary !important;
      min-width: 80px;
    }
  }
}

.Mui-checked {
  color: $primary !important;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS border
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.border-b-g {
  border-bottom: solid 1px lightgray;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS form-greenfee
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.form-greenfee {
  border: solid 1px lightgray;
  box-shadow: $shadow;
  border-radius: 15px;
  padding: 20px;
  width: 100%;

  @media (max-width: $size-lg-plus) {
    display: none;
  }
}

.legend-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;

  &.legend-dot-gara {
    background-color: green;
  }

  &.legend-dot-promozione {
    background-color: blue;
  }
}

.form-struttura-prenota {
  border: solid 1px lightgray;
  box-shadow: $shadow;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
  @media (max-width: $size-lg-plus) {
    display: none;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS underInput
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.underInput {
  position: absolute;
  border: 1px solid lightgray;
  box-shadow: 1px 1px 25px gray;
  border-radius: 15px;
  padding: 1rem;
  max-width: 300px;
  z-index: 10;
  background: $white;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS MuiButtonBase
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.MuiButtonBase-root {
  // background: $gray-light!important;
}

.Mui-selected {
  background-color: $primary !important;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS post-it
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.delete-btn {
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  font-size: 7px;
  font-weight: bold;
  border: $black solid 1px;
  margin-right: 10px;
}

.post-it {
  display: block;
  position: absolute;
  left: 90px;
  right: 60px;
  top: 0;
  min-height: 100%;
  padding: 3px 50px 3px 10px;
  border-left: 5px solid yellow;
  z-index: 2;
  background: rgba(218, 221, 8, 0.5);

  &:hover {
    background: rgba(218, 221, 8, 1);
  }

  &.canale3 {
    border-left: 5px solid rgba(31, 186, 55);
    background: rgba(0, 249, 82, 0.5);
  }

  .left-1 {
    left: 75px;
  }

  .left-2 {
    left: 80px;
  }

  .description {
    font-weight: bold;
    font-size: 10px!important;
    line-height: 10px!important;
  }

  .timeRange {
    font-style: italic;
    font-size: 10px!important;
    line-height: 10px!important;
  }
}

.time__content_container h4.reacapTitle {
  font-size: 16px;
}

.time_listpage {
  padding: 0;
  margin: 0;
}

.time_listpage h4.reacapTitle {
  font-size: 13px;
  margin-bottom: 0;
}

.time_listpage p {
  font-size: 10px;
}

.post-it.length1 {
  height: 200%;
}

.post-it.length2 {
  height: 300%;
}

.post-it.length3 {
  height: 400%;
}

.post-it.length4 {
  height: 500%;
}

.post-it.length5 {
  height: 600%;
}

.post-it.length6 {
  height: 700%;
}

.post-it.length7 {
  height: 800%;
}

.post-it.length8 {
  height: 900%;
}

.time_hotel--panel a strong {
  color: #fff;
}

.list-icon {
  position: relative;
  color: #fff;
}

.fa-shopping-cart { font-size:20px; }

.list-icon .svg-inline--fa {
  vertical-align: 0.1em;
}

.time-address {
  font-size: 0.85em;
  opacity: 0.8;
  line-height: 1.5em;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS Draggable
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.Draggablex {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  box-shadow: 1px 1px 25px gray;
  width: 100%;
}

.dragHand {
  -webkit-user-drag: none;
}

.deskGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
  font-size: 1rem;

  padding: 1rem;
  border-bottom: 1px solid lightgray;
}

.filterBorder {
  border-bottom: 1px solid lightgray;
  padding-bottom: 11px;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS newmap
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.mapFlex {
  display: flex;
  flex-direction: row-reverse;
}

@media (max-width: 1199px) {
  .mapFlex {
    flex-direction: column;
  }
  .dispDisplayer {
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}

.detailDisplayer {
  position: relative;

  padding: 1rem;
  justify-content: center;
  align-items: center;
  top: -220px;
}

.detailGrid {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-auto-rows: 50px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 15px;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.dispDisplayer {
  row-gap: 1rem;
}

@media (min-width: 1200px) {
  .mappa-large {
    overflow: hidden;
    position: fixed;
    top: 85px;
    bottom: 0;
    width: 100%;
    height: auto;
  }
  .fixy {
    position: fixed;
    top: 75px;
    bottom: 0;
    background: white;
    overflow: auto;
    left: 0;
  }
  .mappy {
    width: 50%;
    height: auto;
    left: 50%;
    position: fixed;
    top: 78px;
    bottom: 0;
  }
}

.mappa-smallx {
  height: 50vh;
  width: 100%;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS blockC
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.blockC {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 0px grey, inset 10px 10px 200px black;
  border-radius: 15px;

  .btn {
    margin-top: 15px;
  }
}

.gridCategorie {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 4fr);
  grid-auto-rows: 300px;
}

@media (max-width: 992px) {
  .gridCategorie {
    grid-template-columns: 1fr ;
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .gridCategorie {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

@media (min-width: 992px) {
  .gridCategorie > *:nth-child(4n + 1),
  .gridCategorie > *:nth-child(4n + 4) {
    grid-column: auto / span 2;
  }
}

.gridCategorie:first-child {
  grid-column-start: 2;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS headerGrid
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.headerGrid {
  display: grid;
  gap: 0.6rem;
  grid-template-columns: 1fr 9fr;
}

.internalGrid {
  display: grid;
  gap: 0.8rem;
  grid-template-columns: 3fr 3fr 3fr;
  border: 0px solid lightgray;

  color: white;
  border-radius: 12px;
  font-size: 14px;
  padding: 0px;
  align-items: center;

  @media (min-width: 576px) {
    grid-template-columns: 3fr 3fr 3fr;
  }

  @media (min-width: 768px) {
    gap: 0.4rem;
    grid-template-columns: 5fr 3fr 2fr;
    padding: 10px;
    background: linear-gradient(
      90deg,
      rgba(39, 40, 122, 0.5) 0%,
      rgba(167, 24, 86, 0.5) 100%
    );
    box-shadow: inset 0 0 2000px rgba(236, 236, 236, 0.2);
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS show-overlay
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.show-overlay {
  background-color: rgba(3, 3, 3, 0.459);
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
}

.show-overlaylogin {
  background: $gradient;
  position: fixed;
  z-index: 999;
  width: 100vw;
  min-height: 100vh;
  height:100vh;
  overflow-y:auto; 

  .modalloginchiudi {
    color: $white;
  }

  .copyright-string {
    color: $white;
  }
}

.icon-login {
  font-size: 18px;
  margin-left: 5px;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS show-overlay
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.golfMarker {
  background-color: rgb(0, 195, 31);
}

.golfMarker.current {
  background-color: $primary;
}

.structureMarkerx {
  background-color: rgb(35, 37, 125);
}
.structureMarkerx.current {
  background-color: $primary;
}

.poiMarkerx {
  background-color: #f5a742;
  color: white;
  width: 20px !important;
  height: 20px !important;
  font-size: 10px;
}

.Markerx {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
}

.packetMarkerx {
  background-color: rgba(167, 24, 86, 1);
}

.Markerx img {
  width: 15px;
  height: 15px;
}

.markerDetail {
  width: 260px;
  height: 240px;
  z-index: 100;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  box-shadow: 1px 1px 25px black;
}

.markerDetail__title {
  width: 100%;
  color: $black !important;
  font-weight: 600;

  padding: 1rem;
  font-size: 13px;
  border-top: 1px solid white;
  background-color: $white;
  border-radius: 0px 0px 15px 15px;
}

.markerDetail__title .btn i:before {
  color: white;
}

@media (max-width: 1199px) {
  .markerDetail {
    width: 200px;
    height: 200px;
  }
}

.bounce-7 {
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS row-recap-container RiepilogoPage
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.row.disabled {
  color: #ccc;
  background-color: transparent !important;

  h3 {
    color: #ccc;
    background-color: transparent !important;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * A_SETTINGS section-fidality
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.section-fidality {
  .fidality-box img {
    border-radius: 50%;
    margin: 40px;
  }
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * YT VideoPlayer 
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.ytp-pause-overlay {
  display: none !important;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * Header Cart 
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

.cart-badge-position {
  position: relative;
  top: -7px;
  left: 35px;
  background: #27287a;
}

/*:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::: -- * text-privacy
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
.text-privacy {
  margin: 40px 0px;
  p {
    margin: 5px 0!important;
    padding: 0!important;
  }
  h5 {
    margin: 0px 0!important;
    padding: 0!important;
  }
}


.golfDateModal {
  max-height: 90vh;
}

.accorded {
  margin:10px 0px ;
}

@media (min-width: 576px) {
  .accorded {
    margin:10px 20px ;
  }

}


.bg-gray {
  background: rgba(0, 0, 0, 0.05);
}