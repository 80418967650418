

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mappa-large {
  height: 100vh;
  width: 100%;
  position:relative;
}
.mappa-small {
  height: 60vh;
  width: 100%;
}



/********* Scheda anagrafica utente (DummyAnagrafica) *********/

.scheda-anagrafica-utente {
  margin-top: 50px !important;
  display: flex;
  flex-direction: column;
  background-color: rgb(226, 226, 226);
  width: 400px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 20px;
}

.scheda-anagrafica-utente > img {
  width: 150px;
  align-self: flex-end;
}

.scheda-anagrafica-utente > .testi {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.scheda-anagrafica-utente > .testi > p {
  font-weight: 700;
}

.scheda-anagrafica-utente > .testi > p > span {
  font-weight: 400;
  margin-left: 20px;
}

/*********** HEADER ************/

/*
#header {
  padding: 10px !important;
}
*/

.container-fh {
  height: inherit;
  /* background-color: orange; */
}

.left-side {
  width: auto !important;
}

/*.gradiente {
  !*provvisorio*!
  background: rgb(39, 40, 122);
  background: linear-gradient(
    90deg,
    rgba(39, 40, 122, 1) 0%,
    rgba(167, 24, 86, 1) 100%
  );
}*/

.right-side {
  padding: 3px;
  width: auto;
  margin-bottom: 10px;
}

/*.header-row {
  !* background-color: pink; *!
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: inherit;
}*/

/* .giallo {
  background-color: yellow;
} */

.menu-centrale {
  /* background-color: red; */
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-wrap: nowrap;
}

.navbar-centrale-header ul {
  /* background-color: yellow !important; */
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px;
  flex-wrap: nowrap;
}

.navbar-centrale-header ul li {
  /* background-color: orange !important; */
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-centrale-header ul li a {
  color: white;
  text-decoration: none;
}

/* .navbar-centrale-header ul li a:hover,
.navbar-centrale-header ul li a:active + li {
  border-bottom: 2px solid green;
} */
#logo {
  width: fit-content !important;
}
#logo > img {
  height: 50px;
}

.box {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.box > span {
  color: white;
}

.box1 > img,
.box2 > img,
.box3 > img {
  height: 20px;
}

.box2 > img {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.box2 {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.user-name {
  position: absolute;
  width: 100%;
  height: 56px;
  left: 0px;
  top: 162px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #23257d;
  justify-content: center;
}

.area-riservata-link-active {
  position: static;
  width: 100%;
  height: 40px;
  left: 5px;
  top: 0px;
  background-color: #ffffff;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #a91354;

  /* Inside Auto Layout */

  flex: none;
}

.area-riservata-link {
  position: static;
  width: 100%;
  height: 40px;
  left: 5px;
  top: 0px;
  background-color: #ffffff;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
}

.vedi-tutti-link-active {
  position: static;
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: #ffffff;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #a91354;

  /* Inside Auto Layout */

  flex: none;
}

.vedi-tutti-link {
  position: static;
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: #ffffff;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
}

.search-bar {
  width: 90%;
}
.modifica-profilo-link-active {
  position: absolute;
  width: 100%;
  height: 40px;
  left: 0px;
  top: 190px;
  background-color: #ffffff;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #a91354;

  /* Inside Auto Layout */

  flex: none;
}

.modifica-profilo-link {
  position: absolute;
  width: 100%;
  height: 40px;
  left: 0px;
  top: 190px;
  background-color: #ffffff;
  border: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
}

.ibg-internal-title {
  font-size: 18px;
}
.ibg-location-list {
  padding: 5px 0;
}
.ibg-location-list img {
  padding-right: 5px;
}
.btn.btn-ibg-primary {
  background: #a92154;
  color: #fff;
  font-size: 0.85em;
  font-weight: bold;
  padding: 10px 25px;
}
.btn.btn-ibg-outline {
  border: 1px solid #a92154;
  color: #a92154;
  background: transparent;
  font-size: 0.85em;
  font-weight: bold;
  padding: 10px 25px;
}
.react-calendar__navigation__label__labelText {
  text-transform: capitalize;
  font-size: 1.2em;
}
.punti-fidelity-text {
  position: relative;
  width: 129px;
  height: 29px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;
}

.punti-fidelity {
  position: relative;
  width: 129px;
  height: 29px;
  display: flex;
  flex-direction: column;
  padding-top: 7px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  align-items: center;
  text-align: center;

  color: #ffffff;
  /* Best Golf */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;
  background: linear-gradient(89.82deg, #23257d 0%, #a91354 100%);
  border-radius: 50px;
}

.modifica-profilo {
  position: relative;
  width: 111px;
  height: 22px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #9c9c9c;
}
.left-side {
  text-align: left;
}

.title {
  position: relative;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  /* or 129% */

  text-align: center;
  letter-spacing: -0.408px;
}
.green-fee {
  /* Green Fee */
  position: relative;
  width: 365px;
  height: 60px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;

  color: #000000;
}

.nomehotel {
  position: relative;
  width: 350px;
  height: 14.43px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #000000;

  /* Inside Auto Layout */
}
.indirizzohotel {
  position: relative;
  width: 350px;
  height: 14.43px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #888888;
}
.nottigreenfee {
  /* 7 notti, 10 green fee */
  position: relative;
  width: 350px;
  height: 14.43px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.datacarrello {
  position: relative;
  width: 200px;
  height: 30px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 32px;
  /* or 246% */

  display: flex;
  align-items: center;

  color: #000000;
}
.datoplain {
  position: relative;
  width: 200px;
  height: 30px;

  font-family: Roboto;
  font-style: normal;
  font-weight: plain;
  font-size: 13px;
  line-height: 32px;
  /* or 246% */

  display: flex;
  align-items: center;

  color: #000000;
}
.modifica {
  /* ultimi 4 posti */
  position: relative;
  width: 77px;
  height: 30px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  margin: 0 20px;
  color: #9c9c9c;
}

.separatore {
  /* Line 33 */
  position: relative;
  width: 365px;
  height: 0px;

  border: 0px solid #d2d2d2; /*Era a 1*/
}

.nottigreenfeeebig {
  /* 7 notti, 10 Green Fee */
  position: relative;
  width: 100%;
  height: 52px;
  left: 24px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  display: flex;
  align-items: center;

  color: #000000;
}

.totalecarrello {
  /* Totale: 2590� */
  height: 52px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;
  text-align: right;

  color: #a91354;
}

.terminiecondizioni {
  height: 52px;
  height: 52px;
  left: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;
  text-align: right;

  color: #a91354;
}
/*.time__content_container h4 {
  font-size: 1.3em;
}
.time__content_container .card {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
  border-radius: 20px;
}
.time__content_container .card-img-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.time__content_container .card-title a {
  color: #333;
}*/
.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}
.react-calendar__navigation {
  margin-bottom: 10px;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
}
.react-calendar__navigation button {
  background: #fff;
  border: 0;
  padding: 10px;
  font-size: 25px;
}
.react-calendar__navigation__label {
  font-size: 20px;
}
.react-calendar__tile {
  background: #fff;
  border: 0;
  padding: 10px;
  margin-bottom: 4px;
  color: #7a7585;
}
.react-calendar__tile:disabled {
  color: #c6c6c6;
}
.react-calendar__tile--range {
  background: #f2f2f2;
  color: #a92154;
  border: 1px solid #f2f2f2;
}
.react-calendar__tile--rangeStart {
  background: #a92154;
  color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-color: #a92154;
}
.react-calendar__tile--rangeEnd {
  background: #a92154;
  color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-color: #a92154;
}

/*.time_date--navigation {
  padding-bottom: 0px;
  font-size: 1.3em;
}
.time_date--navigation button:not(.time_header-back) {
  border: 0;
  background: #fff;
}
.time_date--navigation strong {
  line-height: 1.2em;
}
.time_date--navigation a {
  color: #fff;
  display: inline-flex;
}
.time_date--navigation a:hover {
  color: #fff;
}
.time_date--navigation strong span {
  font-size: 0.85em;
  color: #aaa;
}
.time_date--navigation .fa-w-14 {
  width: 1.2em;
  height: 1.2em;
}*/

.time_current_date {
  -webkit-box-shadow: 0px -7px 16px -2px rgba(0, 0, 0, 0.13);
  box-shadow: 0px -7px 16px -2px rgba(0, 0, 0, 0.13);
  padding: 10px 20px;
  border: 1px solid #eaeaea;
  border-bottom: 1px solid #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: -1px;
}
/*.time_hotel--panel {
  background: #a91354;
  color: #fff;
  font-size: 0.9em;
  padding: 3px 0;
}*/
/*.time_hotel--panel a,
.time_hotel--panel strong {
  color: #fff;
}*/
.time__content_container {
  padding-bottom: 85px;
}
.time__content_container p {
  padding-bottom: 0;
  margin-bottom: 0;
}
.time_bottomCart,
.fixed-bottom {
  background: #fff;
  border-top: 1px solid #eaeaea;
  padding: 10px 0px 10px;
  -webkit-box-shadow: 0px -7px 16px -2px rgba(0, 0, 0, 0.13);
  box-shadow: 0px -7px 16px -2px rgba(0, 0, 0, 0.13);
}
.time_bottomCart p {
  line-height: 19px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.time_bottomCart .price {
  color: #a91354;
}
.time_bottomCart .price span {
  font-weight: 700;
}
.time__back {
  background: transparent;
  border: 0;
}

.fidelity_points {
  background: #fff;
  color: #a91354;
  font-size: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-align: right;
}
/*.post-it {
  display: block;
  position: absolute;
  left: 70px;
  right: 36px;
  top: 0;
  min-height: 100%;
  padding: 3px 50px 3px 10px;
  border-left: 5px solid yellow;
  z-index: 2;
  background: rgba(218, 221, 8, 0.5);
}
.post-it:hover {
  background: rgba(218, 221, 8, 1);
}

.post-it.canale3 {
  border-left: 5px solid #23b323;
  background: rgba(0, 249, 82, 0.5);
}*/


/*.time-hour-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 55px;
  border-top: 1px solid #eaeaea !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.time-hour-list:first-child {
  border-top: 0 !important;
}
.time-hour-list:hover {
  z-index: auto !important;
}
.time-hour-list button {
  color: #000;
  background: transparent;
  border: 0;
  padding: 0 30px;
}
.time-hour-list button:hover {
  background: #a91354;
}
.time-hour-list .dt a {
  color: #fff;
}*/


/*.time__content_container h4.reacapTitle {
  font-size: 16px;
}
.time_listpage {
  padding: 0;
  margin: 0;
}
.time_listpage h4.reacapTitle {
  font-size: 13px;
  margin-bottom: 0;
}
.time_listpage p {
  font-size: 10px;
}

.post-it.length1 {
  height: 200%;
}
.post-it.length2 {
  height: 300%;
}
.post-it.length3 {
  height: 400%;
}
.post-it.length4 {
  height: 500%;
}
.post-it.length5 {
  height: 600%;
}
.post-it.length6 {
  height: 700%;
}
.post-it.length7 {
  height: 800%;
}
.post-it.length8 {
  height: 900%;
}
.time_hotel--panel a strong {
  color: #fff;
}
.list-icon {
  display: inline-block;
  position: relative;
}
.list-icon .svg-inline--fa {
  vertical-align: 0.1em;
}*/
/*.badge-small-top {
  position: absolute;
  top: -10px;
  right: -20px;
  font-size: 12px !important;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  line-height: 20px !important;
  background: rgb(39, 40, 122) !important;
}*/

/*.post-it.left-1 {
  left: 75px;
}
.post-it.left-2 {
  left: 80px;
}

.post-it .description {
  font-weight: bold;
  font-size: 1.2em;
}
.post-it .timeRange {
  font-style: italic;
}

.time-address {
  font-size: 0.85em;
  opacity: 0.8;
  line-height: 1.5em;
}*/
/*.time_header {
  color: #fff;
  height: 60px;
  padding: 5px 0px;
  background: rgb(39, 40, 122);
  background: linear-gradient(
    90deg,
    rgba(39, 40, 122, 1) 0%,
    rgba(167, 24, 86, 1) 100%
  );
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
}*/
/*.time_header + .container {
  padding-top: 80px !important;
}*/
.text-on-form {
  font-weight: bold;
  padding-top: 4px !important;
}
.time-page input,
.time-page input[type="text"],
.time-page input[type="password"],
.time-page input[type="email"],
.time-page input[type="number"],
.time-page textarea,
.time-page select {
  padding-top: 14px;
  padding-left: 15px;
}

.time-page select {
  padding-top: 18px;
  padding-left: 11px;
  padding-bottom: 3px;
}
/*.time_header h2 {
  font-size: 14px;
  line-height: 36px;
  padding: 0;
  margin: 0;
}*/
.time_dayOfWeek {
  font-size: 0.55em !important;
  text-transform: uppercase;
  line-height: 1em;
}
/*.time_header .icon {
  height: 22px;
  width: auto;
}*/
/*.time_header-dates {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  line-height: 36px;
}*/
/*.time_header-back {
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0);
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
}*/
/*.ibg-back {
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  padding-right: 2px;
  line-height: 26px;
}*/
.time_selectService {
  font-size: 0.75em;
  padding: 5px 0;
}
.time_moreInfo {
  color: #aaa;
}

/*.time__slidingHeader {
  padding: 20px 0px;
  color: #fff;
  background: #a91354;
  height: 118px;
  margin-bottom: 20px;
  position: relative;
}*/
/*.time__slidingHeader .text-small,
.time_header .text-small {
  text-transform: uppercase;
  font-size: 0.7em;
}*/
/*.time__slidingHeader:after {
  display: block;
  content: "";
  background: rgb(39, 40, 122);
  width: 88px;
  height: 80px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -44px;
  border: 1px solid transparent;
  border-radius: 5px;
}*/

/*.daySlideSelection {
  color: #fff;
  width: 100px;
}*/
/*.daySlideSelection a {
  color: #fff;
}*/
/*.daySlideSelection .day {
  text-transform: uppercase;
  font-size: 0.65em;
}*/
/*.daySlideSelection .number {
  font-weight: bold;
  font-size: 1em;
}*/
/*.daySlideSelection .month {
  font-size: 0.55em;
  line-height: 0.55rem;
}*/


.time_ibg-title {
  font-size: 1.2em;
}

.time_icon-delete {
  position: absolute;
  right: 8px;
  top: 8px;
}
.time_icon-edit {
  position: absolute;
  right: 8px;
  top: 44px;
}
/*.recapContainer {
  font-size: 0.9em;
  margin: 0 15px;
}*/
/*.recapDate {
  font-size: 0.85em;
  line-height: 1.6em;
}*/
.overflow-auto {
  overflow: auto;
}

input.checkbox {
  width: 15px;
  height: 15px;
  display: inline-block;
}

.recapLink {
  color: #ccc;
}
.recapLink a {
  color: #ccc;
}

/*.color-primary {
  fontsize: 1rem;
  color: #a91354;
}*/

/*.home-selection {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		background-repeat: no-repeat;
		background-size:cover;
  height: 60vh;
  padding-top:10rem;
  padding-bottom:10rem;
  min-height: 512px;
}
@media(min-width:1439px){
.home-selection{
		padding: 10rem 21rem; ;
}
}
.home-selection h3 {
  font-weight: 300;
  color: white;
}*/

/*.home-selection-button-container {
  text-align: center;
  display: flex;
  !* margin: auto; *!
  width: 100%;
  justify-content: center;
  column-gap: 1rem;
  align-items: center;
}*/

/*
.searchButtons {
  width: auto !important;
  min-width: 80px;
  padding: 0 13px !important;
  background: #ffffff3a !important;
  margin: 0 !important;
}
.searchButtons-active {
  padding: 0 13px !important;
  margin: 0 !important;
  width: auto !important;
  background: #a91354 !important;
  min-width: 80px;
}


*/
